const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://legal.mstipmanager.com/',
    gaTrackingId: null,
    trailingSlash: true,
  },
  header: {
    logo: 'logo.svg',
    logoLink: 'https://legal.mstipmanager.com/',
    title: '',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: '',
    links: [{ text: 'Terms of Service', link: '/agreements/tos/' }, { text: 'Privacy Policy', link: '/privacy/privacy' },{ text: 'Contact Us', link: '/contact/' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  }, 
  footer:{
    title: '© 2021 MacMillan, Sobanski & Todd, LLC - Legal Documentation Portal',
  },
  sidebar: {
    forcedNavOrder: [
      '/agreements/', // add trailing slash if enabled above
      '/privacy/',
	  '/contact/',
    ],
    collapsedNav: [
      '/custom/', // add trailing slash if enabled above
    ],
    links: [{ text: 'Log In', link: 'https://www.mstipmanager.com/login/' }],
    frontline: false,
    ignoreIndex: true,
    title:
      "<a href='https://legal.mstipmanager.com'>Legal Docs </a><div class='greenCircle'></div><a href='https://www.mstfirm.com'>MST</a>",
  },
  siteMetadata: {
    title: 'MacMillan, Sobanski & Todd, LLC Services Legal Documentation',
    description: 'MacMillan, Sobanski & Todd, LLC Services Legal Documentation',
    ogImage: null,
    docsLocation: '',
    favicon: '',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;

import * as React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import Link from './link';
import Loadable from 'react-loadable';

import config from '../../config.js';
import LoadingProvider from './mdxComponents/loading';
import { DarkModeSwitch } from './DarkModeSwitch';

const StyledBgDiv = styled('div')`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #4e4d81;
  position: relative;
  display: none;
  background: ${(props) => (props.isDarkThemeActive ? '#001932' : undefined)};

  @media (max-width: 767px) {
    display: block;
  }
`;

const Footer = ({ location, isDarkThemeActive, toggleActiveTheme }) => (
  <StaticQuery
    query={graphql`
      query footerTitleQuery {
        site {
          siteMetadata {
            footerTitle
          }
        }
      }
    `}
    render={(data) => {
        const {
            site: {
              siteMetadata: { footerTitle },
            },
          } = data;

          return (
            <div className={'navBarWrapper'}>
              <nav className={'navBarDefault'}>
                <div className={'navBarHeader'}>
                  <div
                    className={'footerTitle displayInline'}
                    dangerouslySetInnerHTML={{ __html: footerTitle }}
                  />
                </div>
                </nav>
        </div>
      );
    }}
  />
);

export default Footer;